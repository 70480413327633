import { Link as ScrollLink, animateScroll as scroll} from 'react-scroll'

const MenuItem = ({ menuItem }) => {

    return (
        <li className="nav-item">
            <ScrollLink to={menuItem.description} offset={-100} spy={true} smooth={true} duration={500} className='nav-link' activeClass='active'>
                {menuItem.description}
            </ScrollLink>
        </li>
    )
}

export default MenuItem
