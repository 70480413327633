import PostContent from './blogPostComponents/PostContent';
import BlogNavigation from './blogPostComponents/BlogNavigation';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import BlogHeader from './blogPostComponents/BlogHeader';

const BlogPost = ({ posts }) => {

    const { id } = useParams();

    return (
        <>
            {posts.filter(post => post.id === parseInt(id)).map((post) => {
                return      <div key={post.id}>
                                <BlogHeader post={post} />
                                <section key={ post.id } className="blogs">
                                    <div className="container">
                                        <div className="row">
                                
                                        <PostContent post={post} />
                                        <BlogNavigation posts={posts} />
                
                                        </div>
                                    </div>
                                </section>
                            </div>
                        
            })}
            
        </>
    )
}

export default BlogPost
