import { useState, useEffect } from 'react'
import Sidemenu from './headerComponents/Sidemenu'
import Navbar from './headerComponents/Navbar'
import BannerContainer from './headerComponents/BannerContainer'
import { Route, Switch } from 'react-router'

const Header = ({ menuList, registerSection }) => {

    const section = {id: 0, description: 'Home'};
    const [showSideMenu, setShowSideMenu] = useState(false);

    const toggleShowSideMenu = () => {
        setShowSideMenu(!showSideMenu);
    }

    useEffect(() => {
        registerSection(section);
    }, []);
    
    return (
        <header className="header">
            <Navbar menuList={menuList} toggleShowSideMenu={toggleShowSideMenu} />
            <Switch>
                <Route exact path='/'>
                    <BannerContainer scrollId={section.description} />
                </Route>
            </Switch>
            <Sidemenu menuList={menuList} showSideMenu={showSideMenu} toggleShowSideMenu={toggleShowSideMenu} />
        </header>
    )
}

export default Header