import portfolioPic from '../../../img/portfolio-pic-01.png'
import titleImg from '../../../img/title_img-min.png'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const BannerContainer = ({ scrollId }) => {
    return (
        <div className="bg-light-gray padding-100px-top" id={scrollId}>
            <div className="container">
                <div className="row">
                    <div className="col-md-7 col-sm-12 order-2 order-md-1 xs-padding-50px-tb">
                        {/* Start Left Content */}
                        <div className="padding-twenty-top sm-padding-fifteen-top xs-no-padding">
                            <h5 className="no-margin font-size18 xs-font-size16 text-medium-gray padding-twelve-top sm-padding-five-top xs-no-padding">Hello, I am</h5>
                            <h1 className="text-uppercase font-size48 md-font-size42 sm-font-size36 xs-font-size26 font-weight-700 margin-20px-bottom">Benjamin Mertens</h1>
                            <p className="width-50 md-width-75 xs-width-100 font-size16 xs-font-size14 border-top border-bottom padding-10px-tb">IT-Consultant &amp; Start Up Enthusiast</p>
                            <div className="social-links margin-30px-bottom xs-margin-20px-bottom">
                                <a href=""><i className="fab fa-facebook-f"></i></a>
                                <a href=""><i className="fab fa-twitter"></i></a>
                                <a href=""><i className="fab fa-google-plus-g"></i></a>
                                <a href=""><i className="fab fa-dribbble"></i></a>
                                <a href=""><i className="fab fa-youtube"></i></a>
                                <a href=""><i className="fab fa-linkedin-in"></i></a>
                            </div>
                            <Link className="btn white" to={ '/blog/all' }>View lates Posts <FontAwesomeIcon icon={ faArrowRight } className="font-size15 margin-5px-left" /></Link>
                        </div>
                        {/* End Left Content */}
                    </div>
                    <div className="col-md-5 col-sm-12 order-1 order-md-2 text-right xs-text-center xs-border-bottom xs-padding-nineteen-half-lr border-color-medium-gray">
                        {/* Start Right Image */}
                        <img className='cust-title-img' src={titleImg} alt="" />
                        {/* End Right Image */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerContainer
