import './App.css';
import './css/plugins/bootstrap.min.css'
import './css/plugins/animate.css'
import './css/plugins/helper.css'
import './css/plugins/magnific-popup.css'
import Header from './components/header/Header';
import BlogSection from './components/blogSection/BlogSection';
import About from './components/about/About';
import Experience from './components/experience/Experience';
import Footer from './components/footer/Footer';
import BlogPost from './components/blog/blogPost/BlogPost';
import BlogOverview from './components/blog/blogOverview/BlogOverview';
import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Imprint from './components/imprint/Imprint';


function App() {

  const [menuList, setMenuList] = useState([]);


  const registerSection = (menuItem) => {
    const addItem = menuList.filter(item => item.id === menuItem.id); 
    addItem.length === 0 && setMenuList(menuList => [ ...menuList, menuItem ]);
  }

  const [posts, setPosts] = useState([]);


  const fetchPosts = async() => {
      const res = await fetch('https://wp.benjamin-mertens.de/wp-json/wp/v2/posts?_embed');
      const data = await res.json();
  
      return data;
  }

  useEffect(() => {
      const getPosts = async() => {
          const postsFromServer = await fetchPosts(); 
          setPosts(postsFromServer);
      }
      getPosts();
  }, []);

  return (
      <Router>
        <div>
          <Header registerSection={registerSection} menuList={menuList} />
            <Switch>
              <Route exact path='/'> 
                <BlogSection registerSection={registerSection} />
                <About registerSection={registerSection} />
                <Experience registerSection={registerSection} />
              </Route>
              <Route exact path='/blog/post/:id'>
              <BlogPost posts={ posts } />
              </Route>
              <Route exact path='/blog/:filter'>
                <BlogOverview posts={ posts }/>
              </Route>
              <Route exact path='/imprint'>
                <Imprint />
              </Route>
            </Switch>
          <Footer />
        </div>
      </Router>
  );
}

export default App;
