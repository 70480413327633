export function getPostExcerpt(post) {
    return (post.excerpt.rendered.substr(0, post.excerpt.rendered.indexOf('<a')) + '</p>')
}

export function getPostTitleImg(post) {
    return post._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url;
}

export function getPostCategories(post) {

    const fullList = post._embedded['wp:term'][0];
    const assignedList = post.categories;

    const filteredCat = fullList.filter((cat) => {
        return assignedList.some((assCat) => {
            return assCat === cat.id;
        })
    })

    return filteredCat;
}

export function getPostDateString(post) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    const date = new Date(post.date);
    return date.toLocaleDateString('en-US', options);
}