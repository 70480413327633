import MenuItem from "./MenuItem"

const Sidemenu = ({menuList, showSideMenu, toggleShowSideMenu}) => {
    return (
        <>
            <div className={`side-menu ${showSideMenu && 'side-menu-active'}`}>
                <div className="inner-wrapper">
                    <span className="btn-close" id="btn_sidebar_colse" onClick={toggleShowSideMenu}></span>
                    <nav className="side-nav width-100">
                        <ul className="navbar-nav ml-auto">
                            
                        {menuList.map(
                            (menuItem) => (
                                <MenuItem key={menuItem.id} menuItem={menuItem} />
                            )
                        )}

                        </ul>
                    </nav>
    
                    <div className="side-footer width-100">
                        <div className="social-links">
                            <a href=""><i className="fab fa-facebook-f"></i></a>
                            <a href=""><i className="fab fa-twitter"></i></a>
                            <a href=""><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <a id="close_sidebar" style={{display: showSideMenu && 'block'}}></a>
        </>
    )
}

export default Sidemenu
