import BlogPic from '../../../img/blog/1.jpg'
import { getPostCategories, getPostExcerpt, getPostTitleImg, getPostDateString } from '../../../helper/postHelper';
import { Link } from 'react-router-dom';

const BlogItem = ({ post }) => {
    return (
        <div className="col-lg-4 sm-margin-20px-bottom">
            <div className="item text-center">
                <div className="post-img">
                    <img src={ getPostTitleImg(post) } alt="" />
                </div>
                <div className="content">
                    <div className="tag alt-font font-weight-300">
                        { getPostCategories(post).map((cat) => { return <Link key={ cat.name } to={ '/blog/' + cat.name }> { cat.name + ' ' } </Link> }) } { getPostCategories(post).length > 0 && '|' } <span className="font-size12 display-inline-block">{ getPostDateString(post) }</span>
                    </div>
                    <h5 className="margin-15px-bottom"><Link to={ '/blog/post/' + post.id }>{ post.title.rendered }</Link></h5>
                    <div dangerouslySetInnerHTML={{__html: getPostExcerpt(post) }} />
                    {/* <a href="single-post.html" className="read-more">Read more</a> */}
                    <Link to={ '/blog/post/' + post.id } className='read-more'>Read more</Link>
                </div>
            </div>
      </div>
    )
}

export default BlogItem