import { useParams } from "react-router-dom"
import { getPostCategories } from "../../../helper/postHelper"
import BlogHeader from "../blogPost/blogPostComponents/BlogHeader"
import BlogNavigation from "../blogPost/blogPostComponents/BlogNavigation"
import BlogMainItem from "./blogOverviewComponents/BlogMainItem"

const BlogOverview = ({ posts }) => {

    const { filter } = useParams();

    return (
        <>
            <BlogHeader />

            <section className="blogs ">
                <div className="container">
                    <div className="row">
                        {/*  start blog left*/}
                        <div className="col-md-8 xs-margin-30px-bottom">
                            <div className="posts">

                                { filter === 'all' && posts.map((post) => {
                                    return <BlogMainItem key={ post.id } post={ post } />
                                }) }

                                { filter !== 'all' && posts.filter(post => {
                                    return getPostCategories(post).some((assCat) => {
                                        return assCat.name === filter;
                                    })
                                }).map((post) => {
                                    return <BlogMainItem key={ post.id } post={ post } />
                                }) }

                                {/* <div className="pagination text-uppercase">
                                    <ul>
                                        <li><a href="javascript:void(0);"><i className="fas fa-long-arrow-alt-left margin-5px-right xs-display-none"></i> Prev</a></li>
                                        <li className="active"><a href="javascript:void(0);">1</a></li>
                                        <li><a href="javascript:void(0);">2</a></li>
                                        <li><a href="javascript:void(0);">3</a></li>
                                        <li><a href="javascript:void(0);">Next <i className="fas fa-long-arrow-alt-right margin-5px-left xs-display-none"></i></a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                        {/*  end blog left*/}
                        
                        <BlogNavigation posts={ posts }/>

                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogOverview
