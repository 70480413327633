import { getPostTitleImg, getPostDateString, getPostCategories } from "../../../../helper/postHelper"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom"

const BlogMainItem = ({ post }) => {
    return (
        <div className="post">
            <div className="post-img">
                <a href="single-post.html" className="width-100">
                    <img src={ getPostTitleImg(post) } alt="" />
                </a>
            </div>
            <div className="content">
                <div className="post-meta">
                    <div className="post-title">
                        <h5>
                            <Link to={ '/blog/post/' + post.id }>{post.title.rendered}</Link>
                        </h5>
                    </div>
                    <ul className="meta">
                        <li>
                            <FontAwesomeIcon icon={faFolderOpen} /> &nbsp; 
                            { getPostCategories(post).map((cat) => {
                                return <Link to={ '/blog/' +  cat.name }> { cat.name } </Link>
                            }) }
                        </li>
                        <li>
                            <a>
                                <FontAwesomeIcon icon={faCalendar} /> &nbsp; { getPostDateString(post) }
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="post-cont" dangerouslySetInnerHTML={{__html: post.excerpt.rendered }}/>
                <Link to={ '/blog/post/' + post.id } className="btn white">Read More</Link>
            </div>
        </div>
    )
}

export default BlogMainItem
