import { getPostDateString, getPostTitleImg } from '../../../../helper/postHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'

 
const PostContent = ({ post }) => {
    return (
        <div className="col-md-8 xs-margin-30px-bottom">
            <div className="posts">
                {/*  start post*/}
                <div className="post mb-80">
                    <div className="post-img">
                        <img src={ getPostTitleImg(post) } alt="" />
                    </div>
                    <div className="content">
                        <div className="post-meta">
                            <div className="post-title">
                                <h5>{ post.title.rendered }</h5>
                            </div>
                            <ul className="meta">
                                <li>
                                    <a>
                                        <FontAwesomeIcon icon={faCalendar} /> &nbsp; { getPostDateString(post) }
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="post-cont" dangerouslySetInnerHTML={{__html: post.content.rendered }} />
                        {/* <div className="share-post">
                            <span>Share Post</span>
                            <ul>
                                <li><i className="icofont icofont-social-facebook"></i></li>
                                <li><i className="icofont icofont-social-twitter"></i></li>
                                <li><i className="icofont icofont-social-behance"></i></li>
                                <li><i className="icofont icofont-social-youtube"></i></li>
                            </ul>
                        </div> */}
                    </div>
                </div>
                {/*  start post*/}

            </div>
        </div>
    )
}

export default PostContent
