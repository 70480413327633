import { useEffect, useState } from 'react';
import BlogItem from './BlogComponents/BlogItem';


const BlogSection = ({ registerSection }) => {

    const section = {id: 1, description: 'Blog'};
    const [posts, setPosts] = useState([]);

    const fetchPosts = async() => {
        const res = await fetch('https://wp.benjamin-mertens.de/wp-json/wp/v2/posts?_embed');
        const data = await res.json();
    
        return data;
      }

    useEffect(() => {
        const getPosts = async() => {
            const postsFromServer = await fetchPosts(); 
            setPosts(postsFromServer);
        }
        registerSection(section);
        getPosts();
    }, []);

    return (
        <section className="blog">
            <div className="container">
                <div className="row">
                    <div className="col-12 center-col margin-70px-bottom sm-margin-40px-bottom xs-margin-30px-bottom text-center" id={section.description}>
                        <h3 className="font-weight-700 font-size32 md-font-size27 sm-font-size24 xs-font-size20 section-title">Blog</h3>
                    </div>
                </div>
                <div className="row">

                    { posts.slice(0,3).map((post) => {
                        return <BlogItem key={ post.id } post={post} />
                    }) }

                </div>
            </div>
        </section>
    )
}

export default BlogSection