import { useEffect, useState } from 'react'
import ExperienceItem from './experienceComponents/ExperienceItem';

const Experience = ({ registerSection }) => {

    const cvSteps = [
    {
        id: 4, 
        category: 'PROFESSIONAL',
        startYear: '2021', 
        duration: 'till now',
        title: 'Teamlead Consulting Unit Materialsmanagement & Purchasing',
        subtitle: 'Delaware Consulting (ex Dr. Lauterbach & Partner GmbH)',
        description: 'Just recently I took over responsibility for a small consulting unit at Delaware Consulting Germany. As the responsibility is quite new to me, I am looking forward learining from my team and my colleagues to continously improve my self.'
    },{
        id: 3, 
        category: 'PROFESSIONAL',
        startYear: '2018', 
        duration: '3 Years',
        title: 'Senior Business Consultant',
        subtitle: 'Dr. Lauterbach & Partner GmbH',
        description: 'With taking the step being a Senior Consultant I took over responsibility as project lead for customer projects such as product owner for several products of our internal product portfolio. Gaining a lot of experience in those disciplines.'
    },{
        id: 2, 
        category: 'PROFESSIONAL',
        startYear: '2015', 
        duration: '3 Years',
        title: 'Associate Business Consultant',
        subtitle: 'Dr. Lauterbach & Partner GmbH',
        description: 'As an Associate Business Consultant I worked on national such as international projects in print and packaging industry. Together with the customer we have been analyzing and improving his business processes using SAP ERP aswell as other products from the SAP product portfolio.'
    },{
        id: 1, 
        category: 'PROFESSIONAL',
        startYear: '2012', 
        duration: '3 Years',
        title: 'Junior Software Developer',
        subtitle: 'Julius Fröbus GmbH',
        description: 'Within my apprenticeship I have been working on several web applications. Working with the customer I have been responsible for taking up customers requirements, planning the architecture such as realization, deployment and application support.'
    },{
        id: 5, 
        category: 'ENTREPRENEUR',
        startYear: '2015', 
        duration: 'till now',
        title: 'Freelancer',
        subtitle: '',
        description: 'As a freelancer I was able to help multiple customers with their technical challenges on databases, web applications and web shops. If you are interested in consulting me for your individual project, I am looking forward hearing from you.'
    },{
        id: 6, 
        category: 'ENTREPRENEUR',
        startYear: '2015', 
        duration: '4 Years',
        title: 'Founder Aixsinthe',
        subtitle: '',
        description: 'Together with a group of three friends we founded Aixsinthe, a premium Absinthe brand for collectors market. In 2019 I left the company due to different projects I wanted to work on. The rest of the team is still successfully working on Aixsinthe launching a new product in 2021.'
    },{
        id: 7, 
        category: 'EDUCATION',
        startYear: '2018', 
        duration: '2 Years',
        title: 'Business Management (Master of Science)',
        subtitle: 'Steinbeis University Berlin',
        description: ''
    },{
        id: 8, 
        category: 'EDUCATION',
        startYear: '2015', 
        duration: '3 Years',
        title: 'Business Information Systems (Bachelor of Arts)',
        subtitle: 'ASW Neunkirchen',
        description: ''
    },{
        id: 9, 
        category: 'EDUCATION',
        startYear: '2012', 
        duration: '3 Years',
        title: 'Certified professional in software development',
        subtitle: 'IHK Cologne',
        description: ''
    }]

    const [mode, setMode] = useState('PROFESSIONAL');

    const changeMode = (mode) => {
        setMode(mode)
    }

    const section = {id: 3, description: 'Experience'};

    useEffect(() => {
        registerSection(section);
    }, []);

    return (
        <section className='portfolio' id={section.description}>
            <div className="container">
                <div className="row">
                    <div className="col-12 center-col margin-70px-bottom sm-margin-40px-bottom xs-margin-30px-bottom text-center">
                        <h3 className="font-weight-700 font-size32 md-font-size27 sm-font-size24 xs-font-size20 section-title">My Experience</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="filtering col-sm-12 text-center">
                        <span onClick={() => changeMode('PROFESSIONAL')} className={`${mode === 'PROFESSIONAL' && 'active'}`}>Professional</span>
                        <span onClick={() => changeMode('ENTREPRENEUR')} className={`${mode === 'ENTREPRENEUR' && 'active'}`}>Entrepreneur</span>
                        <span onClick={() => changeMode('EDUCATION')} className={`${mode === 'EDUCATION' && 'active'}`}>Education</span>
                    </div>
                    <div className="col-md-12">
                        <div className="main-timeline">

                            {cvSteps.filter(step => step.category === mode).map((step) => {
                                return <ExperienceItem item={step} key={step.id} />
                            })}

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Experience
