import { Link } from "react-router-dom"

const Footer = () => {
    return (
        <>
            {/* Start Contact Section */}
            <section className="contact no-padding-bottom" data-scroll-index="6">
                <div className="bg-light-gray padding-30px-tb border-top border-color-medium-gray">
                    <div className="container">
                        <div className="row info">

                            {/* start quickly contact */}
                            <div className="col-lg-6 col-md-6 item text-center border-right xs-no-border-right xs-border-bottom border-color-medium-gray xs-margin-20px-bottom xs-padding-20px-bottom">
                                <span className="icon font-size32"><i className="icon-map"></i></span>
                                <div className="cont">
                                    <h6>Location</h6>
                                    <p>Cologne, Germany</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 item text-center">
                                <span className="icon font-size32"><i className="icon-envelope"></i></span>
                                <div className="cont">
                                    <h6>Email</h6>
                                    <p>kontakt@benjamin-mertens.de</p>
                                </div>
                            </div>
                            {/* end quickly contact */}

                        </div>
                    </div>
                </div>

            </section>
            {/* End Contact Section */}

            {/*  Start Footer Section */}
            <footer className="bg-black">
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 text-center">
                                <Link className="font-size13 text-uppercase letter-spacing-1 text-light-gray" to='/imprint'>Imprint</Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div id="back-to-top" className="back-to-top reveal">
                    <i className="fa fa-angle-up"></i>
                </div> */}
            </footer>
            {/* End Footer Section */}
        </>
    )
}

export default Footer
