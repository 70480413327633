import logoDark from '../../../img/logo-dark.png'
import MenuItem from './MenuItem'
import React, { useState, useLayoutEffect } from 'react'
import { Link, Route, Switch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'

const Navbar = ({toggleShowSideMenu, menuList}) => {

    const [scrollPosition, setPosition] = useState(0);

    useLayoutEffect(() => {
        function updatePosition() {
            setPosition(window.scrollY);
        }

        window.addEventListener('scroll', updatePosition);

        updatePosition();
        return () => window.removeEventListener('scroll', updatePosition);
    }, []);

    return (
        <nav className={`navbar navbar-expand-lg border-none bg-light-gray ${scrollPosition > 50 && 'nav-scroll'}`}>
            <div className="container sm-padding-10px-tb sm-padding-15px-lr">

                {/* start logo */}
                <a className="logo">
                    {/* <img src={logoDark} alt="Ashton - One Page Portfolio" title="Ashton - One Page Portfolio" /> */}
                    <h5 style={{ fontWeight: '800', margin: '0px', fontSize: '33px' }}>Mertens<span style={{ color: '#1ab394' }}>.</span></h5>
                </a>
                {/* end Logo */}

                {/* navbar links */}
                <div className="collapse navbar-collapse" id="navbarContent">
                    <ul className="navbar-nav ml-auto">
                        <Switch>
                            <Route exact path='/'>
                                {menuList.map(
                                    (menuItem) => (
                                        <MenuItem key={menuItem.id} menuItem={menuItem} />
                                    )
                                )}
                                <li className="nav-item" style={{ paddingLeft: '20px' }}>  
                                    <a className="btn valuator" activeClass='active' href='https://valuator.benjamin-mertens.de' >Valuate <FontAwesomeIcon icon={ faGlobe } className="font-size15 margin-5px-left" /></a>
                                </li>
                            </Route>
                            <Route path='/blog'>
                                <Link className='nav-link' to='/'>Home</Link>
                            </Route>
                            <Route path='/imprint'>
                                <Link className='nav-link' to='/'>Home</Link>
                            </Route>
                        </Switch>
                    </ul>
                </div>
                {/* end navbar links */}

            </div>

            <Switch> 
                <Route exact path='/'> 
                    <a onClick={toggleShowSideMenu} className="sidemenu_btn sm-display-none" id="sidebar_toggle">
                        <span></span> <span></span> <span></span>
                    </a>
                </Route>
            </Switch>

    </nav>
    )
}

export default Navbar
