import { getPostCategories } from '../../../../helper/postHelper.js'
import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';

const BlogHeader = ({ post }) => {

    const { filter } = useParams();

    const getHeadline = () => {
        if(typeof(post) === 'undefined') {
            return ( filter === 'all' ? 'Blog' : filter ); 
        } else {
            getPostCategories(post).map((cat) => {
                return cat.name;
            });
        }
    }

    return (
        <section className="blog-header">
            <div className="container">
                <div className="row">
                    <div className="text-center col-md-12">
                        <h5> { getHeadline() } </h5>
                        <Link to={ '/' }>Home</Link>
                        <Link to={ '/blog/all' }>Blog</Link>
                        {
                            typeof(post) !== 'undefined' && <a className="active">{ post.title.rendered }</a>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogHeader
