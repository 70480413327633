import { Link } from "react-router-dom"
import { useState, useEffect } from "react";



const BlogNavigation = ({ posts }) => {
    const [categories, setCategories] = useState([]);


    const fetchCategories = async() => {
        const res = await fetch('https://wp.benjamin-mertens.de/wp-json/wp/v2/categories');
        const data = await res.json();
    
        return data;
    }

    useEffect(() => {
        const getCategories = async() => {
            const categoriesFromServer = await fetchCategories(); 
            setCategories(categoriesFromServer);
        }
        getCategories();
    }, []);

    return (
        <div className="col-md-4">
            <div className="side-bar">
                <div className="widget">
                    <div className="widget-title">
                        <h6>Recent Posts</h6>
                    </div>
                    <ul>
                        {posts.slice(0, 5).map((post) => {
                            return <li key={ post.id }><Link to={ '/blog/post/' + post.id }>{ post.title.rendered }</Link></li>
                        })}
                    </ul>
                </div>
                <div className="widget">
                    <div className="widget-title">
                        <h6>Categories</h6>
                    </div>
                    <ul>
                        {categories.filter(cat => cat.id !== 1).map((cat) => {
                            return <li key={ cat.id }><Link to={ '/blog/' + cat.name }>{ cat.name }</Link></li>
                        })}
                    </ul>
                </div>
            </div>
        </div>
                        
    )
}

export default BlogNavigation
