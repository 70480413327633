import { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons'
import cv from '../../res/CV.pdf'

const About = ({registerSection}) => {

    const section = {id: 2, description: 'About'};

    useEffect(() => {
        registerSection(section);
    }, []);

    return (
        <section className='bg-light-gray' id={section.description}>
            <div className="container">
                <div className="row">
                    <div className="col-12 center-col margin-70px-bottom sm-margin-40px-bottom xs-margin-30px-bottom text-center">
                        <h2 className="font-size34 md-font-size30 sm-font-size26 xs-font-size24 font-weight-700 section-title">About Me</h2>
                    </div>
                </div>
                <div className="row">
                    {/* start about me section*/}
                    <div className="col-lg-6 col-md-12 sm-margin-30px-bottom">
                        <p className="font-size15 xs-font-size14">Starting my career as a Junior Software Developer, going over working as a Junior- and Senior IT-Business-Consultant till taking over responsibility for a consulting unit at Delaware Consulting Germany, teached me a lot about tech and busniess. Are interested in me bringing in my skilles in your company? Click the button below to download my CV!</p>
                        <a className="btn white">
                            <FontAwesomeIcon icon={ faArrowAltCircleDown } className="font-size16" />
                            <a href={ cv } target='_blank'><span className="alt-font margin-5px-left">Download My CV</span></a>
                        </a>
                    </div>
                    {/* end about me section*/}

                    {/* start skill section*/}
                    <div className="col-lg-6 col-md-12">
                        <div className="padding-20px-left sm-no-padding">
                            <div className="skills width-100 margin-40px-bottom sm-margin-30px-bottom xs-no-margin-bottom">

                                <div className="prog-item">
                                    <p>ERP-Systems and Processes</p>
                                    <div className="skills-progress"><span data-value='95%' style={{width: '95%'}}></span></div>
                                </div>
                                <div className="prog-item">
                                    <p>Frontend Development (ReactJS, Angular)</p>
                                    <div className="skills-progress"><span data-value='85%' style={{width: '85%'}}></span></div>
                                </div>
                                <div className="prog-item">
                                    <p>Product- and Project-Management</p>
                                    <div className="skills-progress"><span data-value='90%' style={{width: '90%'}}></span></div>
                                </div>
                                <div className="prog-item">
                                    <p>Backend Development (Java, Firebase)</p>
                                    <div className="skills-progress"><span data-value='80%' style={{width: '80%'}}></span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end skill section*/}
                </div>
            </div>
        </section>
    )
}

export default About
