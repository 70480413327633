const ExperienceItem = ({ item }) => {
    return (
        <div className="timeline">
            <div className="icon"></div>
            <div className="date-content">
                <div className="date-outer">
                    <span className="date">
                            <span className="month">{ item.duration }</span>
                    <span className="year">{ item.startYear }</span>
                    </span>
                </div>
            </div>
            <div className="timeline-content">
                <h5 className="title">{ item.title }</h5>
                <p>{ item.subtitle }</p>
                <p className="description">
                    { item.description }
                </p>
            </div>
        </div>
    )
}

export default ExperienceItem
